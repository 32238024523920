import React from "react";
import { Text, View } from "react-native";
import tw from "twrnc";
import IconButton from "../IconButton";
import { BackIcon3x, PlusIcon3x } from "../../utils/icons";

interface NavHeaderProps {
  title?: string;
  onPress: () => void;
  onAddTicket?: () => void;
}

const NavHeader = ({ title, onPress, onAddTicket }: NavHeaderProps) => {
  return (
    <View style={tw`flex-row justify-between w-full py-[10px]`}>
      <IconButton
        style={tw`self-start justify-self-start ml-[10px] rounded-lg h-full`}
        icon={BackIcon3x}
        theme="dark"
        size={26}
        onPress={onPress}
      />

      <View style={tw`-ml-[40px] self-center`}>
        {title && <Text style={tw`text-white font-bold text-xl`}>{title}</Text>}
      </View>

      {onAddTicket ? (
        <IconButton
          style={tw`self-start justify-self-start ml-[10px] mr-[10px] rounded-lg h-full`}
          icon={PlusIcon3x}
          size={26}
          theme="dark"
          onPress={onAddTicket}
        />
      ) : (
        <View />
      )}
    </View>
  );
};

export default React.memo(NavHeader);
