import React, { useEffect, useState, useContext } from "react";

import { Alert, Text, View, TouchableOpacity } from "react-native";
import { useNavigate } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { paymentsConfig } from "../../../../utils/config";

import CheckoutForm from "../../components/CheckoutForm";

import style from "./style";
import { PaymentsContext } from "../../PaymentsContext";
import { EventContext } from "../../../events/EventContext";
import { AuthContext } from "../../../auth/AuthContext";
import { convertIntegerAmountToDollarString } from "../../../../utils/text";
import TicketRow from "../../../events/components/TicketRow/TicketRow";
import NavHeader from "../../../../components/NavHeader/NavHeader";

const stripePromise = loadStripe(paymentsConfig.publishableKey);

const getRealPrice = (listedPrice) => {
    if (listedPrice === 0) {
        return 0;
    } else {
        return (listedPrice + 30) / (1 - 0.029);
    }
};

const PaymentsView = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {
        paymentTotal,
        referralCode,
        ticketDetails,
        setTicketDetails,
        ticketInfo,
    } = useContext(PaymentsContext);

    const { event, buyTicket, checkTicketLimitsAndFetchEvent } =
        useContext(EventContext);

    const { userAuth } = useContext(AuthContext);

    const navigate = useNavigate();

    const buyTicketPrepared = (eventData) => {
        buyTicket(
            userAuth.user.uid,
            eventData,
            ticketDetails,
            setTicketDetails,
            referralCode
        );
    };

    // const codedTickets = [];
    // const uncodedTickets = [];

    // for (let i = 0; i < event["eventTicketTypes"].length; i++) {
    //     const ticket = event["eventTicketTypes"][i];

    //     if (ticket.ticketCode !== "") {
    //         codedTickets.push(ticket);
    //     } else {
    //         uncodedTickets.push(ticket);
    //     }
    // }

    // const finalTickets = uncodedTickets.concat(codedTickets);

    const finalTickets = [];

    for (let i = 0; i < event["eventTicketTypes"].length; i++) {
        const ticket = event["eventTicketTypes"][i];
        finalTickets.push(ticket);
    }

    const findIndexFromName = (name) => {
        for (let i = 0; i < event["eventTicketTypes"].length; i++) {
            const ticket = event["eventTicketTypes"][i];
            if (ticket.ticketName === name) {
                return i;
            }
        }
    };

    const checkTicketLimits = async () => {
        const { ticketsAvailable, eventData } =
            await checkTicketLimitsAndFetchEvent(event.id, ticketDetails);
        return { ticketsAvailable, eventData };
    };

    return (
        <Elements stripe={stripePromise}>
            <NavHeader
                onPress={() => {
                    navigate(-1);
                }}
            />
            <View style={style.container}>
                <View>
                    <Text style={style.header}>Your Order</Text>
                    <View style={style.description}>
                        {Object.entries(ticketDetails).map(
                            ([index, quantity]) => (
                                <TicketRow
                                    key={index}
                                    ticketName={finalTickets[index].ticketName}
                                    ticketPrice={
                                        finalTickets[index]?.ticketPrice === 0
                                            ? 0
                                            : getRealPrice(
                                                  finalTickets[index]
                                                      ?.ticketPrice
                                              ).toFixed(2)
                                    }
                                    ticketDescription={""}
                                    soldOut={false}
                                    display={true}
                                    quantity={quantity}
                                />
                            )
                        )}
                    </View>
                </View>
                <View style={style.buttonsRow}>
                    <View style={style.totalRow}>
                        <Text style={style.subtitle}>Total:</Text>
                        <Text style={style.subtitle}>
                            ${convertIntegerAmountToDollarString(paymentTotal)}
                        </Text>
                    </View>
                    <View>
                        <View style={style.spacer}></View>
                        <CheckoutForm
                            total={Math.floor(paymentTotal)}
                            style={style.applePay}
                            buyTicketPrepared={buyTicketPrepared}
                            checkTicketLimits={checkTicketLimits}
                        />
                    </View>
                </View>
            </View>
        </Elements>
    );
};

export default PaymentsView;
