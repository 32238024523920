import React, { useContext, useState } from "react";
import { Text, View } from "react-native";
import tw from "twrnc";
import IconButton from "../../../../components/IconButton/index.js";
import {
  PlusIcon3x,
  MinusIcon3x,
  ClockIcon,
  UnlockIcon,
  LockIcon,
} from "../../../../utils/icons.js";
import { PaymentsContext } from "../../../payments/PaymentsContext.js";
import { red } from "../../../../utils/color.js";
import { convertIntegerAmountToDollarString } from "../../../../utils/text.js";
import { Timestamp } from "firebase/firestore";

interface TicketRowProps {
  index: number;
  id?: string;
  ticketName: string;
  ticketDescription: string;
  ticketPrice: number;
  maxTicketsPerGuest: number;
  isScheduledTicket: boolean;
  releaseTime?: Timestamp | Date | string;
  isTicketHidden?: boolean;
  isTicketLocked?: boolean;
  soldOut?: boolean;
  display?: boolean;
  quantity?: number;
  feesText?: string;
}

const TicketRow: React.FC<TicketRowProps> = ({
  index,
  id,
  ticketName,
  ticketDescription,
  ticketPrice,
  maxTicketsPerGuest,
  isScheduledTicket,
  releaseTime,
  isTicketHidden,
  isTicketLocked,
  soldOut,
  display,
  quantity: displayQuantity,
  feesText,
}) => {
  const { setPaymentTotal, paymentTotal, setTicketDetails, ticketDetails } =
    useContext(PaymentsContext);
  const key = id || index;
  const initialQuantity = ticketDetails.hasOwnProperty(key)
    ? ticketDetails[key]
    : 0;
  const [quantity, setQuantity] = useState<number>(initialQuantity);

  const isTicketScheduled = isScheduledTicket && releaseTime;
  const ticketReleaseTime =
    releaseTime instanceof Timestamp
      ? releaseTime.toDate()
      : new Date(releaseTime);
  const dateString = isTicketScheduled
    ? ticketReleaseTime.toLocaleDateString()
    : "";
  const timeString = isTicketScheduled
    ? ticketReleaseTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "";

  const isTicketReleased = isTicketScheduled
    ? ticketReleaseTime.getTime() <= Date.now()
    : true;

  const getRealPrice = (listedPrice: number): number => {
    if (listedPrice === 0) {
      return 0;
    } else {
        return (listedPrice + 30) / (1 - 0.029);
    }
  };

  const handleIncrement = (): void => {
    if (quantity < maxTicketsPerGuest) {
      setQuantity(quantity + 1);
      setPaymentTotal(paymentTotal + getRealPrice(ticketPrice));

      const newTicketDetails = { ...ticketDetails };
      if (newTicketDetails.hasOwnProperty(key)) {
        newTicketDetails[key] += 1;
      } else {
        newTicketDetails[key] = 1;
      }
      setTicketDetails(newTicketDetails);
    }
  };

  const handleDecrement = (): void => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      setPaymentTotal(paymentTotal - getRealPrice(ticketPrice));

      const newTicketDetails = { ...ticketDetails };
      if (newTicketDetails.hasOwnProperty(key)) {
        newTicketDetails[key] -= 1;
        if (newTicketDetails[key] === 0) {
          delete newTicketDetails[key];
        }
        setTicketDetails(newTicketDetails);
      }
    }
  };

  return (
    <View style={tw`flex-1 flex-row`}>
      <View style={tw`flex-1 flex-col ml-[30px] mb-10`}>
        <View style={tw`flex-row items-center`}>
          <Text
            style={tw`${display ? "text-[15px]" : "text-[18px]"} text-white`}
          >
            {ticketName}
          </Text>
          {isTicketHidden && (
            <IconButton
              icon={UnlockIcon}
              style={tw`px-2`}
              size={18}
              theme={"dark"}
              tintColor={"grey"}
            />
          )}
        </View>
        {ticketDescription.length > 0 ? (
          <Text style={tw`text-gray-500 text-[17px] pt-[5px]`}>{`${
            ticketDescription + "\n"
          }`}</Text>
        ) : null}
        {isTicketReleased ? (
          <View style={tw`flex-row items-baseline`}>
            <Text
              style={tw`${
                display ? "text-[15px]" : "text-[17px] font-bold pt-[5px]"
              } text-white`}
            >
              {`$${convertIntegerAmountToDollarString(ticketPrice)}`}
            </Text>
            {!soldOut && feesText && (
              <Text style={tw`text-gray-500 ml-[5px] text-[10px]`}>
                {feesText}
              </Text>
            )}
          </View>
        ) : (
          <Text style={tw`text-white text-[14px] mt-[0.22rem]`}>
            {"Locked until: " + dateString + ", " + timeString}
          </Text>
        )}
      </View>
      {display ? (
        <View style={tw`flex-row items-center mb-10 pr-3`}>
          <Text style={tw`text-white text-[15px]`}>x{displayQuantity}</Text>
        </View>
      ) : soldOut ? (
        <Text style={tw`text-white font-bold text-[18px] pr-5`}>Sold out!</Text>
      ) : maxTicketsPerGuest <= 0 ? (
        <Text style={tw`text-white font-bold text-[18px] pr-5`}>
          Guest Limit Hit!
        </Text>
      ) : isTicketReleased && !isTicketLocked ? (
        <View style={tw`flex-row items-center mb-10`}>
          <IconButton
            icon={MinusIcon3x}
            style={tw`px-3 py-2.5`}
            size={25}
            theme={"dark"}
            onPress={handleDecrement}
            disabled={quantity <= 0}
          />
          <Text style={tw`text-white text-[15px]`}>
            {ticketDetails[key] ? ticketDetails[key] : 0}
          </Text>
          <IconButton
            icon={PlusIcon3x}
            style={tw`px-3 py-2.5`}
            size={25}
            theme={"dark"}
            onPress={handleIncrement}
            disabled={quantity >= maxTicketsPerGuest}
          />
        </View>
      ) : (
        <>
          {isTicketLocked && (
            <IconButton
              icon={LockIcon}
              style={tw`px-3 py-2.5`}
              size={25}
              theme={"dark"}
              tintColor={red}
            />
          )}
          {!isTicketReleased && (
            <IconButton
              icon={ClockIcon}
              style={tw`px-3 py-2.5`}
              size={25}
              theme={"dark"}
              tintColor={red}
            />
          )}
        </>
      )}
    </View>
  );
};

export default TicketRow;
